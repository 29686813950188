import React from 'react'
import { roomDisplayName } from 'shared/utils/room'
import { Deferred } from 'shared/utils/web/deferred'
import { DialogButton } from '../../components/Button'
import { Dialog, USER_CLOSED_DIALOG } from '../../components/Dialog'

export const AcknowledgeAlertDialog: React.FC<{
  room: string
  roomName: string
  deferred: Deferred<void>
}> = ({ room, roomName, deferred }) => {
  return (
    <Dialog onClose={() => deferred.reject(USER_CLOSED_DIALOG)}>
      <div>
        Nouvelle alerte {roomDisplayName(room)}
        {roomName && (
          <span>
            <br />
            {roomName}
          </span>
        )}
      </div>
      <div className="flex flex-row">
        <DialogButton onClick={() => deferred.resolve()}>OK</DialogButton>
      </div>
    </Dialog>
  )
}
