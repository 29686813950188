import { useEffect, useRef, useState } from 'react'
import { ms } from 'shared/utils/time'
import { useFirebase } from '../hooks/useFirebase'
import { YellowButton } from './Button'

const AUTO_UPDATE_DELAY = ms(5, 'seconds')
const GRACE_DELAY = ms(5, 'minutes')

export const UpdateAppBanner = () => {
  const { data: version } = useFirebase('version')

  const [countdownStartTime, setCountdownStartTime] = useState<number>()
  const reloadTimeoutRef = useRef<NodeJS.Timeout>()

  useEffect(() => {
    if (
      import.meta.env.VITE_VERSION &&
      version &&
      version !== import.meta.env.VITE_VERSION
    )
      setCountdownStartTime(Date.now())
  }, [version])

  useEffect(() => {
    if (reloadTimeoutRef.current) clearTimeout(reloadTimeoutRef.current)

    if (countdownStartTime !== undefined)
      reloadTimeoutRef.current = setTimeout(
        () => window.location.reload(),
        AUTO_UPDATE_DELAY,
      )
  }, [countdownStartTime])

  function startGracePeriod() {
    setCountdownStartTime(undefined)
    setTimeout(() => setCountdownStartTime(Date.now()), GRACE_DELAY)
  }

  if (countdownStartTime === undefined) return null

  return (
    <div className="bg-background dark:bg-background-dark sticky inset-0 flex flex-col space-y-3 p-4">
      <div className="text-center">
        Une nouvelle version de cette application est disponible
      </div>
      <div className="flex flex-row items-center justify-between space-x-3">
        <YellowButton onClick={() => window.location.reload()}>
          Mettre à jour
        </YellowButton>
        <YellowButton onClick={startGracePeriod}>Plus tard</YellowButton>
      </div>
    </div>
  )
}
