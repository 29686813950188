import { AlertType } from 'shared/types/alert'
import { TimeRange } from 'shared/types/timeRange'
import { DateString, UserId } from 'shared/types/utils'

export interface Device extends FacilityDevice {
  facilityId: string
  facilityName: string
  monitoringTimeRange: TimeRange
}

interface SnoozeInterval {
  start: number
  end: number
}

export type SnoozeHistory = Partial<
  Record<AlertType, Record<FirebaseKey, SnoozeInterval>>
>

export type FirebaseASUser = {
  email: string
  role: 'AS'
  facilityId: string
  zoneId?: string
  zoneLocked?: boolean
  notificationDisabled?: boolean
}

type FirebaseAdminUser = {
  email: string
  role: 'admin'
  phoneNumber?: string
  zoneId: undefined // Hacky for typed methods
  notificationDisabled: undefined // Hacky for typed methods
  facilityId: undefined // Hacky for typed methods
}

export type FirebaseUser = FirebaseASUser | FirebaseAdminUser

export type CallStatus =
  | 'queued'
  | 'initiated'
  | 'ringing'
  | 'in-progress'
  | 'completed'
  | 'busy'
  | 'failed'
  | 'no-answer'
  | 'canceled'

export type CallStatusHistory = {
  duration: string // although it represents a number
  status: CallStatus[]
}

export type FirebaseCall = {
  id: number
  callerId: string
  callerEmail: string
  calleeId?: string
  calleeEmail?: string
}

export type FirebaseUserMessage = {
  uid: string
  fromAdmin: boolean
  timeStamp: number
  text: string
}

export const automaticUid = 'automatic'

export type RawAlert = Omit<Alert, 'soundURL'> & {
  sound: string /* wav encoded sound */
}

interface BubbleBase {
  timestamp: number
  userId: UserId
}

export interface TextBubble extends BubbleBase {
  text: string
}

export interface AudioBubble extends BubbleBase {
  soundURL: string
  soundPath: string
}

export function isTextBubble(bubble: Bubble): bubble is TextBubble {
  return (bubble as TextBubble).text !== undefined
}

export function isAudioBubble(bubble: Bubble): bubble is AudioBubble {
  return (bubble as AudioBubble).soundURL !== undefined
}

// export interface ImageBubble extends BubbleBase {
//   imageURL: string
// }

export type Bubble = TextBubble | AudioBubble // | ImageBubble

export type ConversationId = FirebaseKey
export type Conversation = Record<FirebaseKey, Bubble>
export type Conversations = Record<ConversationId, Conversation>

interface Discussion {
  conversationId: ConversationId
  unreadCount: number
}

export type UserDiscussions = Partial<Record<UserId, Discussion>>
export type Discussions = Partial<Record<UserId, UserDiscussions>>

export type Emergencies = Record<FirebaseKey, Emergency>

export interface Emergency {
  source: string
  date: DateString
  sound: string
  handledAt?: DateString
  handledBy?: UserId
  endAt?: DateString
}

export type VideoSource = 'menu' | 'popup'
