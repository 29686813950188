import { serverTimestamp } from 'shared/firebase/serverValue'
import {
  AlertEvaluation,
  AlertHandling,
  AlertValidation,
} from 'shared/types/alert'

export function alertValidation(uid: string) {
  const value: AlertValidation = {
    uid,
    timeStamp: serverTimestamp(),
    isValidated: true,
  }

  return value
}

export function alertEvaluation(uid: string, isConfirmed: boolean) {
  const value: AlertEvaluation = {
    uid,
    isConfirmed,
    timeStamp: serverTimestamp(),
  }

  return value
}

export function alertHandling(playProgress: number, isUseful?: boolean) {
  const value: AlertHandling = {
    playProgress,
    timeStamp: serverTimestamp(),
  }

  if (isUseful !== undefined) value.isUseful = isUseful

  return value
}
