import { DateTime } from 'luxon'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { bucketShiftHour, ms, noonDate } from 'shared/utils/time'
import { isWithinTimeRange, timeRangeString } from 'shared/utils/timeRange'
import { collator } from 'shared/utils/web/collator'
import { getRealZone, roomIsInZoneAtTime } from 'shared/utils/zone'
import { useUser } from '../../components/UserProvider'
import { ACTIVITY_RESUMED_EVENT } from '../../constants'
import { useFirebase } from '../../hooks/useFirebase'
import { AlertProvider } from './AlertContext'
import { ContactsProvider } from './contactsProvider'
import { EmergencyCall } from './EmergencyCall'
import { EmergencyCallContext } from './EmergencyCallContext'
import { Menu } from './Menu'
import { Room } from './Room'
import { RoomNamesProvider } from './RoomNamesContext'
import { TutorialPopupDialog } from './TutorialPopupDialog'

const LAST_POPUP_TIME = 'lastPopupTime'

export const Live: React.FC<{
  facilityId: string
  facility: Facility
}> = ({ facilityId, facility }) => {
  const { isCallInProgress, isCallAnswered, amICalling, amICalled } =
    useContext(EmergencyCallContext)

  const { devices, monitoringTimeRange } = facility
  const [isMonitoring, setIsMonitoring] = useState(false)
  const [alertsDate, setAlertsDate] = useState('2000-01-01')
  const [now, setNow] = useState(DateTime.now())
  const [showPopupVideo, setShowPopupVideo] = useState(false)
  const user = useUser()

  const startTimer = useCallback(() => {
    const timeout = ms(2, 'days')
    const lastPopupTime = localStorage.getItem(LAST_POPUP_TIME)

    const elapsed = lastPopupTime ? Date.now() - parseInt(lastPopupTime, 10) : 0

    const randomTimeout = Math.max(Math.random() * (timeout - elapsed), 0)

    const timer = setTimeout(() => {
      setShowPopupVideo(true)
      localStorage.setItem(LAST_POPUP_TIME, Date.now().toString())

      startTimer()
    }, randomTimeout)

    return () => clearTimeout(timer)
  }, [])

  useEffect(() => {
    startTimer()
  }, [startTimer])

  // Check time periodically to compare with monitoringTimeRange
  // Also updates visible rooms based on zones and current time
  useEffect(() => {
    const recomputeState = () => {
      const now = DateTime.now()

      const isMonitoring = isWithinTimeRange(now, monitoringTimeRange)
      const usePreviousDay = !isMonitoring && now.hour >= bucketShiftHour
      const day = usePreviousDay ? 1 : 0
      const alertsDate = noonDate(now.minus({ day }))

      setIsMonitoring(isMonitoring)
      setAlertsDate(alertsDate)
      setNow(now)
    }

    recomputeState()

    document.addEventListener(ACTIVITY_RESUMED_EVENT, recomputeState)

    const intervalId = setInterval(recomputeState, ms(1, 'minute'))

    return () => {
      document.removeEventListener(ACTIVITY_RESUMED_EVENT, recomputeState)
      clearInterval(intervalId)
    }
  }, [monitoringTimeRange])

  useEffect(() => {
    if (window.Android?.onPageLoaded) window.Android.onPageLoaded()
  }, [])

  const { data: zones } = useFirebase(`zones/${facilityId}`)

  const selectedZone = useMemo(() => {
    if (zones && user.role === 'AS' && user.zoneId !== undefined)
      return zones[user.zoneId]

    return undefined
  }, [user, zones])

  const realZone = useMemo(() => {
    if (selectedZone === undefined) return undefined
    return getRealZone(zones ?? {}, selectedZone, now)
  }, [zones, selectedZone, now])

  const sortedDevices = useMemo(
    () =>
      Object.entries(devices ?? {})
        .filter(([, { status }]) => status !== 'pending' && status !== 'spare')
        .sort(([, { room: roomA }], [, { room: roomB }]) =>
          collator.compare(roomA, roomB),
        ),
    [devices],
  )

  const displayedDevices = useMemo(() => {
    if (realZone === undefined) return sortedDevices

    return sortedDevices
      .filter(([, { room }]) => roomIsInZoneAtTime(room, now, realZone))
      .filter(
        ([, { monitoringTimeRange }]) =>
          monitoringTimeRange === undefined ||
          isWithinTimeRange(now, monitoringTimeRange),
      )
  }, [sortedDevices, realZone, now])

  const rooms = sortedDevices.map(([_, device]) => device.room)

  return (
    <AlertProvider>
      <RoomNamesProvider facilityId={facilityId} rooms={rooms}>
        {showPopupVideo && (
          <TutorialPopupDialog onClose={() => setShowPopupVideo(false)} />
        )}
        <div className="sticky top-0 z-10">
          <EmergencyCall />
          {(!isCallInProgress ||
            (!amICalled && !amICalling && isCallAnswered)) && (
            <>
              <ContactsProvider facilityId={facilityId}>
                <Menu facilityId={facilityId} />
              </ContactsProvider>
              {selectedZone && (
                <div className="bg-background dark:bg-background-dark py-4 text-center">
                  <div>
                    Surveillance de <b>{selectedZone.name}</b>
                  </div>
                  {selectedZone !== realZone && (
                    <div className="text-xs">Week-ends et jours fériés</div>
                  )}
                </div>
              )}
              {!isMonitoring && (
                <div className="bg-background dark:bg-background-dark py-4 text-center">
                  <div>
                    <b>Service non activé actuellement</b>
                  </div>
                  <div>Alertes de la nuit dernière</div>
                </div>
              )}
            </>
          )}
        </div>
        <div className="flex flex-col space-y-1 px-1 pt-1">
          {displayedDevices.map(
            ([
              serial,
              { room, status, monitoringTimeRange: deviceMonitoringTimeRange },
            ]) => (
              <Room
                key={serial}
                isMonitoring={isMonitoring}
                serial={serial}
                room={room}
                status={status}
                alertsDate={alertsDate}
                facility={facility.name}
                zone={realZone}
                monitoringTimeRange={
                  deviceMonitoringTimeRange ?? monitoringTimeRange
                }
              />
            ),
          )}
          {displayedDevices.length === 0 && (
            <div className="bg-background-room dark:bg-background-room-dark flex h-72 flex-col items-center justify-center rounded-lg">
              Aucune chambre surveillée
            </div>
          )}
          <div className="self-center py-4 text-center">
            Service activé {timeRangeString(monitoringTimeRange)}
          </div>
        </div>
      </RoomNamesProvider>
    </AlertProvider>
  )
}
