import React, { useState } from 'react'
import { Deferred } from 'shared/utils/web/deferred'
import { DialogButton } from '../../components/Button'
import {
  Dialog,
  USER_BACK_BUTTON,
  USER_CLOSED_DIALOG,
} from '../../components/Dialog'
import { ListItem } from '../../components/ListItem'
import Arrow from '../../icons/arrow.svg?react'
import MessageIcon from '../../icons/message.svg?react'
import PhoneIcon from '../../icons/phone.svg?react'
import Sleeping from '../../icons/sleeping.svg?react'
import Awake from '../../icons/smile.svg?react'
import TV from '../../icons/tv.svg?react'
import { isPhoneCallEnabled } from './android'
import emergencyCallDemoVideoSrc from './emergency-call-demo.mp4'
import alerts1Src from './help-alerts-1.png'
import alerts2Src from './help-alerts-2.png'
import zones from './help-zones.png'
import demoVideoSrc from './oso-ai.mp4'
import { TutorialCarousel } from './TutorialCarousel'

const HelpSections = {
  video: {
    title: 'Vidéo de démonstration',
    content: (
      <video controls autoPlay style={{ objectFit: 'contain' }}>
        <source src={demoVideoSrc} type="video/mp4" />
        Désolé, ce navigateur ne peut pas lire cette vidéo.
      </video>
    ),
  },
  alerts: {
    title: 'Comment gérer les alertes',
    content: (
      <>
        <div>
          Lorsque ARI détecte un bruit inquiétant dans une chambre, une alerte
          est déclenchée sur votre ARI phone en indiquant le numéro de la
          chambre.
        </div>

        <img className="p-2" src={alerts1Src} alt="Nouvelle alerte" />

        {window.Android && (
          <div>
            Lorsqu'une alerte est déclenchée, votre ARI phone vous le notifie
            par une vibration et un flash lumineux, puis une sonnerie.
          </div>
        )}

        <div>
          Après avoir cliqué sur «OK», vous pouvez écouter le son pour
          déterminer si une intervention auprès du résident est nécessaire.
        </div>

        <img className="p-2" src={alerts2Src} alt="Écoute son" />

        <div>
          Vous pourrez évaluer l'utilité de l'alerte après écoute, ou l'enlever
          en appuyant sur la croix.
        </div>
      </>
    ),
  },
  live: {
    title: "Voir l'activité des résidents en temps réel",
    content: (
      <>
        <div>
          Le ARI phone vous donne des informations en temps réel sur l'activité
          des résidents.
        </div>
        <div className="flex flex-row items-center space-x-2">
          <Sleeping className="w-8" fill="currentColor" />
          <span className="flex-1">Le résident est en train de dormir</span>
        </div>

        <div className="flex flex-row items-center space-x-2">
          <Awake className="w-8" fill="currentColor" />
          <span className="flex-1">Le résident est actif</span>
        </div>

        <div className="flex flex-row items-center space-x-2">
          <TV className="w-8" fill="currentColor" />
          <span className="flex-1">La télé est allumée</span>
        </div>
      </>
    ),
  },
  zones: {
    title: 'Sélectionner les chambres surveillées',
    content: (
      <>
        <div>Dans le Menu, appuyer sur "Sélection des chambres".</div>

        <div>
          Choisir d'afficher et de surveiller toutes les chambres ou seulement
          une partie de l'établissement.
        </div>

        <img className="p-2" src={zones} alt="Sélection chambres" />
      </>
    ),
  },
  emergencyCall: {
    title: "Passer un appel d'urgence",
    content: (
      <>
        <div>
          La fonction <b>Appel d'urgence</b> permet de contacter le ARI phone
          d'un·e autre collègue en cas de situation d'urgence.
        </div>
        <div>
          Vous pouvez déclencher un appel en appuyant sur le bouton «Appel
          collègue».
        </div>

        <div className="bg-background-phone dark:bg-background-phone-dark text-menu dark:text-menu-dark mx-auto flex flex-1 flex-row items-center justify-center space-x-3 px-8 py-2">
          <PhoneIcon fill="currentColor" className="w-6" />
          <div>Appel collègue</div>
        </div>
      </>
    ),
  },
  emergencyCallDemo: {
    title: "Tutoriel appel d'urgence",
    content: (
      <video controls autoPlay style={{ objectFit: 'contain' }}>
        <source src={emergencyCallDemoVideoSrc} type="video/mp4" />
        Désolé, ce navigateur ne peut pas lire cette vidéo.
      </video>
    ),
  },
  communication: {
    title: 'Passer un appel',
    content: (
      <>
        <div>
          Pour voir la liste des téléphones de l'établissement, appuyez sur
        </div>
        <div className="bg-background-phone dark:bg-background-phone-dark text-menu dark:text-menu-dark mx-auto flex flex-1 flex-row items-center justify-center space-x-3 px-8 py-2">
          <PhoneIcon fill="currentColor" className="w-6" />
          <div>Répertoire</div>
        </div>
        <div>
          Appuyer sur l'icône téléphone pour l'appeler, ou sur celle de la
          messagerie pour discuter.
          <div className="flex flex-row justify-evenly">
            <div className="bg-background-phone dark:bg-background-phone-dark text-menu dark:text-menu-dark flex h-11 w-11 flex-row items-center justify-center rounded-full">
              <PhoneIcon fill="currentColor" className="h-6" />
            </div>
            <div className="bg-background-phone dark:bg-background-phone-dark text-menu dark:text-menu-dark flex h-11 w-11 flex-row items-center justify-center rounded-full">
              <MessageIcon fill="currentColor" className="h-6" />
            </div>
          </div>
        </div>
      </>
    ),
  },
  tutorials: {
    title: 'Tutoriels vidéo',
    content: <TutorialCarousel isExpanded source="menu" />,
  },
}

export const HelpDialog: React.FC<{
  deferred: Deferred<void>
  showZoneSelection: boolean
}> = ({ deferred, showZoneSelection }) => {
  const [selectedHelpSection, setSelectedHelpSection] = useState<
    keyof typeof HelpSections | null
  >(null)

  return (
    <Dialog onClose={() => deferred.reject(USER_CLOSED_DIALOG)}>
      {!selectedHelpSection ? (
        <>
          <div
            className="flex cursor-pointer flex-row items-center space-x-2"
            onClick={() => deferred.reject(USER_BACK_BUTTON)}
          >
            <Arrow className="w-4" fill="currentColor" />
            <span className="flex-1">Aide à l'utilisation</span>
          </div>
          <div className="flex flex-col space-y-3 overflow-y-auto">
            <ListItem onClick={() => setSelectedHelpSection('video')}>
              {HelpSections.video.title}
            </ListItem>
            <ListItem onClick={() => setSelectedHelpSection('tutorials')}>
              {HelpSections.tutorials.title}
            </ListItem>
            <ListItem onClick={() => setSelectedHelpSection('alerts')}>
              {HelpSections.alerts.title}
            </ListItem>
            <ListItem onClick={() => setSelectedHelpSection('live')}>
              {HelpSections.live.title}
            </ListItem>
            {showZoneSelection && (
              <ListItem onClick={() => setSelectedHelpSection('zones')}>
                {HelpSections.zones.title}
              </ListItem>
            )}
            {isPhoneCallEnabled() ? (
              <ListItem onClick={() => setSelectedHelpSection('communication')}>
                {HelpSections.communication.title}
              </ListItem>
            ) : (
              <ListItem onClick={() => setSelectedHelpSection('emergencyCall')}>
                {HelpSections.emergencyCall.title}
              </ListItem>
            )}
            <ListItem
              onClick={() => setSelectedHelpSection('emergencyCallDemo')}
            >
              {HelpSections.emergencyCallDemo.title}
            </ListItem>
          </div>
        </>
      ) : (
        <>
          <div
            className="flex cursor-pointer flex-row items-center space-x-2"
            onClick={() => setSelectedHelpSection(null)}
          >
            <Arrow className="w-4" fill="currentColor" />
            <span className="flex-1">
              {HelpSections[selectedHelpSection].title}
            </span>
          </div>

          <div className="flex flex-1 flex-col space-y-2 overflow-auto text-base">
            {HelpSections[selectedHelpSection].content}
          </div>
        </>
      )}

      <div className="flex flex-row">
        <DialogButton onClick={() => deferred.reject(USER_CLOSED_DIALOG)}>
          Fermer
        </DialogButton>
      </div>
    </Dialog>
  )
}
