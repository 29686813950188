import { DateTime } from 'luxon'
import { useContext, useState } from 'react'
import { noonDate } from 'shared/utils/time'
import { timeRangeString } from 'shared/utils/timeRange'
import { FacilitySelector } from '../../components/FacilitySelector'
import { DialogInput } from '../../components/Input'
import { facilitiesContext } from '../../contexts/FacilitiesProvider'
import { FakeAlertCreator } from './FakeAlertCreator'
import { RoomComments } from './RoomComments'
import { RoomStateGraphs } from './RoomStateGraphs'
import { UserStats } from './UserStats'

const getMaxDate = () => noonDate(DateTime.now())

export const Admin = () => {
  const { facility } = useContext(facilitiesContext)
  const { monitoringTimeRange } = facility

  // Possibly not defined for new facilities
  const devices = facility.devices ?? {}

  const maxDate = getMaxDate()
  const [date, setDate] = useState(maxDate)

  return (
    <div className="flex flex-col gap-3 p-3">
      <div className="flex flex-row flex-wrap gap-3 self-center">
        <FacilitySelector />
        <DialogInput
          value={date}
          type="date"
          max={getMaxDate()}
          className="text-background-alert dark:text-background-alert-dark"
          onChange={(e) => setDate(e.target.value)}
        />
      </div>

      <div className="self-center">
        Surveillance {timeRangeString(monitoringTimeRange)}
      </div>
      <RoomComments devices={devices} />
      <RoomStateGraphs
        date={date}
        devices={devices}
        monitoringTimeRange={monitoringTimeRange}
      />
      <FakeAlertCreator devices={devices} />
      <div />
      <div />
      <UserStats />
    </div>
  )
}
