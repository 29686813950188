import React, { useContext, useState } from 'react'
import { Centered } from 'shared/components/Centered'
import { FacilityId } from 'shared/types/utils'
import { Deferred } from 'shared/utils/web/deferred'
import { USER_CLOSED_DIALOG } from '../../components/Dialog'
import { Contacts } from './Contacts'
import { Conversation } from './Conversation'
import { contactsContext } from './contactsProvider'

export const Communication: React.FC<{
  deferred: Deferred<void>
  facilityId: FacilityId
}> = ({ deferred, facilityId }) => {
  const { discussions, loading, error } = useContext(contactsContext)

  // undefined when no conversation is displayed
  const [conversationUserId, setConversationUserId] = useState<string>()

  return (
    <div className="bg-background dark:bg-background-dark text-menu dark:text-menu-dark absolute inset-0 flex min-h-screen flex-col space-y-3 p-5">
      {loading && <Centered>Chargement...</Centered>}
      {error && <Centered>Erreur</Centered>}
      {conversationUserId ? (
        <Conversation
          conversationId={discussions?.[conversationUserId]?.conversationId}
          userId={conversationUserId}
          facilityId={facilityId}
          onClose={() => setConversationUserId(undefined)}
        />
      ) : (
        <Contacts
          setConversationUserId={setConversationUserId}
          onClose={() => deferred.reject(USER_CLOSED_DIALOG)}
        />
      )}
    </div>
  )
}
