import React from 'react'
import { Deferred } from 'shared/utils/web/deferred'
import { DialogButton } from '../../components/Button'
import { Dialog, USER_CLOSED_DIALOG } from '../../components/Dialog'

export const ShowSuccessDialog: React.FC<{
  deferred: Deferred<void>
}> = ({ deferred }) => {
  return (
    <Dialog onClose={() => deferred.reject(USER_CLOSED_DIALOG)}>
      <div>Merci pour votre message&nbsp;!</div>
      <div className="text-center text-4xl">👍</div>
      <div>Il sera pris en compte par notre équipe.</div>
      <DialogButton onClick={() => deferred.resolve()}>Fermer</DialogButton>
    </Dialog>
  )
}
