import { DateTime } from 'luxon'
import React, { useContext, useEffect, useState } from 'react'
import { AlertType } from 'shared/types/alert'
import { isObjectEmpty } from 'shared/utils/defined'
import { isDeviceMonitored } from 'shared/utils/device'
import { noonDate } from 'shared/utils/time'
import { isWithinTimeRange } from 'shared/utils/timeRange'
import { YellowButton } from '../../components/Button'
import { facilitiesContext } from '../../contexts/FacilitiesProvider'
import { push } from '../../firebaseMethods'

export const FakeAlertCreator: React.FC<{
  devices: Record<Serial, FacilityDevice>
}> = ({ devices }) => {
  const [isConfirmed, setIsConfirmed] = useState(false)

  const { facility } = useContext(facilitiesContext)

  const handleClick = (kind: AlertKind) => {
    if (isConfirmed) {
      createAlert(kind)
      setIsConfirmed(false)
    } else {
      setIsConfirmed(true)
    }
  }

  useEffect(() => {
    if (isConfirmed) {
      const timeoutId = setTimeout(() => setIsConfirmed(false), 2000)
      return () => clearTimeout(timeoutId)
    }

    return
  }, [isConfirmed])

  async function createAlert(kind: AlertKind) {
    const serials = Object.entries(devices)
      .filter(([_serial, device]) => isDeviceMonitored(device))
      .map(([serial, _device]) => serial)
    const serial = serials[Math.floor(serials.length * Math.random())]
    if (serial === undefined) {
      window.alert('No available serial found, aborting')
      return
    }

    const now = DateTime.now()
    const date = noonDate(now)

    const alert: Alert = {
      date: now.toISO(),
      type: kindAlertType[kind],
      text: `Alerte de test ${kind}`,
      soundURL:
        kind === 'Urgence'
          ? 'https://storage.googleapis.com/oso-ari.appspot.com/00000000149923dc_-MIuU-VgFMcSkYogFXT1.wav'
          : 'https://storage.googleapis.com/oso-ari.appspot.com/0000000008dcda55_-Nlci4qa-RrF7dQYliuS.wav',
    }

    if (kindKeyword[kind]) alert.keywords = [kindKeyword[kind]]

    await push(`alerts/${date}/${serial}`, alert)
  }

  const isMonitoring = isWithinTimeRange(
    DateTime.now(),
    facility.monitoringTimeRange,
  )

  return isObjectEmpty(devices) ? (
    <div>Aucun appareil</div>
  ) : isMonitoring ? (
    <div className="flex flex-row items-center justify-around">
      <div>Créer une alerte :</div>
      {Object.keys(kindAlertType).map((kind) => (
        <YellowButton onClick={() => handleClick(kind as AlertKind)}>
          {isConfirmed ? '🚨 Confirmer ? 🚨' : kind}
        </YellowButton>
      ))}
    </div>
  ) : (
    <YellowButton disabled={true}>Hors plage de surveillance</YellowButton>
  )
}

type AlertKind = 'Urgence' | 'Confort' | 'Réveil'

const kindAlertType: Record<AlertKind, AlertType> = {
  Urgence: 'SPEECH',
  Confort: 'AS_CALL',
  Réveil: 'WAKEUP',
}

const kindKeyword: Record<AlertKind, string | undefined> = {
  Urgence: 'au secours',
  Confort: "s'il vous plait",
  Réveil: undefined,
}
