import React, { useEffect, useMemo, useRef, useState } from 'react'

import { TUTORIAL_VIDEOS } from './tutorial-videos'

import { VideoSource } from 'common/types'
import { serverTimestamp } from 'shared/firebase/serverValue'
import { useUser } from '../../components/UserProvider'
import { push, set } from '../../firebaseMethods'
import useIntersectionObserver from '../../hooks/useIntersectionObserver'

interface VideoComponentProps {
  video: {
    src: string
    poster: string
  }
  source: VideoSource
}

const VideoComponent: React.FC<VideoComponentProps> = ({
  video: { src, poster },
  source,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const { uid } = useUser()

  const [maxPlayProgress, setMaxPlayProgress] = useState(0)
  const [databaseVideoReferenceKey, setDatabaseVideoReferenceKey] =
    useState<string>()

  // store last maxPlayProgress to save it on unmount
  const latestMaxProgressRef = useRef(0)

  useEffect(() => {
    latestMaxProgressRef.current = maxPlayProgress
  }, [maxPlayProgress])

  useEffect(() => {
    const video = videoRef.current

    if (video) {
      const onVideoPlay = async () => {
        const reference = await push(`videos/${uid}`, {
          videoId: src,
          timestamp: serverTimestamp(),
          progress: 0,
          source,
        })
        setDatabaseVideoReferenceKey(reference.key)
      }

      const onVideoTimeUpdate = () => {
        const currentProgress = video.currentTime / video.duration
        setMaxPlayProgress((cur) => Math.max(cur, currentProgress))
      }

      video.addEventListener('play', onVideoPlay)
      video.addEventListener('timeupdate', onVideoTimeUpdate)

      return () => {
        video.removeEventListener('play', onVideoPlay)
        video.removeEventListener('timeupdate', onVideoTimeUpdate)

        const setProgressReference = async (
          user: string,
          key: string,
          progress: number,
        ) => await set(`videos/${user}/${key}/progress`, progress)

        if (databaseVideoReferenceKey && latestMaxProgressRef.current > 0) {
          setProgressReference(
            uid,
            databaseVideoReferenceKey,
            latestMaxProgressRef.current,
          )
        }
      }
    }

    // avoid Not all code paths return a value ts error
    return () => {}
  }, [src, uid, databaseVideoReferenceKey, source])

  const handleIntersection: IntersectionObserverCallback = (entries) => {
    entries.forEach((entry) => {
      if (!entry.isIntersecting) {
        if (videoRef.current && videoRef.current.played.length > 0) {
          videoRef.current.pause()
        }
      }
    })
  }

  useIntersectionObserver(videoRef, handleIntersection, { threshold: 1 })

  return (
    <video
      ref={videoRef}
      controls
      className="max-h-[70vh]"
      poster={poster}
      preload="auto"
    >
      <source src={src} type="video/mp4" />
      <p>
        Votre navigateur ne prend pas en charge les vidéos HTML5. Voici
        <a href={src}>un lien pour télécharger la vidéo</a>.
      </p>
    </video>
  )
}

export const TutorialCarousel: React.FC<{
  isExpanded: boolean
  source: VideoSource
}> = ({ isExpanded, source }) => {
  const startIndex = useMemo(
    () => Math.floor(Math.random() * TUTORIAL_VIDEOS.length),
    [],
  )
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (containerRef.current) {
      const container = containerRef.current
      const videoHeight = container.scrollHeight / TUTORIAL_VIDEOS.length
      const scrollPosition = videoHeight * startIndex

      container.scrollTo({ top: scrollPosition, behavior: 'instant' })
    }
  }, [startIndex, isExpanded])

  return isExpanded ? (
    <div
      ref={containerRef}
      className="flex snap-y snap-mandatory flex-col items-center gap-2 overflow-y-scroll"
    >
      {TUTORIAL_VIDEOS.map((video, index) => (
        <div key={index} className="snap-center items-center justify-center">
          <VideoComponent video={video} source={source} />
        </div>
      ))}
    </div>
  ) : (
    <img src={TUTORIAL_VIDEOS[startIndex].poster} />
  )
}
