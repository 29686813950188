import { alertTypeString } from 'shared/types/alert'
import { ms } from 'shared/utils/time'

export const ALERT_NOTIFICATION_TTL = ms(1, 'hour')

export function alertDisplayText(alert: Alert) {
  if (alert.type === 'BEEP') return `Bip ${alert.text.replace(/_/g, ' ')}`
  if (alert.keywords)
    return alert.keywords.map((keyword) => `"${keyword}"`).join(', ')
  return alertTypeString[alert.type]
}
