import { ALERT_NOTIFICATION_TTL } from 'common/alert'
import { DateTime } from 'luxon'
import React, {
  createContext,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { DateString } from 'shared/types/utils'
import { ms } from 'shared/utils/time'
import { Deferred } from 'shared/utils/web/deferred'
import { USER_CLOSED_DIALOG } from '../../components/Dialog'
import { NO_ALERT_ID } from '../../constants'
import { AcknowledgeAlertDialog } from './AcknowledgeAlertDialog'

export const AlertContext = createContext<{
  onAlertReceived: (
    serial: string,
    alertId: FirebaseKey,
    alertDate: DateString,
    isHandled: boolean,
    room: string,
    roomName: string,
  ) => void
  notificationAlertId: FirebaseKey | undefined
}>({
  onAlertReceived: (
    _serial: string,
    _alertId: FirebaseKey,
    _alertDate: DateString,
    _isHandled: boolean,
    _room: string,
    _roomName: string,
  ) => {},
  notificationAlertId: undefined,
})

type Alert = {
  alertId: FirebaseKey
  dateTime: DateTime
  room: string
  roomName: string
  isHandled: boolean
}

// Should match alert notification TTL
const CLOSE_DELAY = ALERT_NOTIFICATION_TTL

const MERGE_BATCHES_DURATION = ms(0.6, 'second')

/*
Centralizes the display of an AcknowledgeAlertDialog popup when
a new alert arrives.
Alerts are emitted using onAlertReceived by a one-off useEffect,
and only the most recent one is displayed in case there are several.
A timer hides the popup after a set delay.
*/
export const AlertProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const alert = useRef<Alert>()
  const openDialogTimeoutRef = useRef<NodeJS.Timeout>()
  const closeDialogTimeoutRef = useRef<NodeJS.Timeout>()

  const [dialogDeferred, setDialogDeferred] = useState<Deferred<void> | null>(
    null,
  )

  function closeDialog() {
    alert.current = undefined
    setDialogDeferred(null)
  }

  const onAlertReceived = useCallback(
    (
      serial: string,
      alertId: FirebaseKey,
      alertDate: DateString,
      isHandled: boolean,
      room: string,
      roomName: string,
    ) => {
      if (window.Android?.onAlertDisplayed) {
        window.Android.onAlertDisplayed(NO_ALERT_ID, serial)
      }

      const dateTime = DateTime.fromISO(alertDate)

      // May happen when phone syncs after getting back online
      const alertIsTooOld = dateTime.diffNow().as('milliseconds') < -CLOSE_DELAY
      const alertIsNewer = !alert.current || dateTime >= alert.current.dateTime
      if (alertIsTooOld || !alertIsNewer) return // Skip acknowledge dialog

      // Update most recent alert
      alert.current = { alertId, dateTime, room, roomName, isHandled }

      // A short grace period to gather all recently validated alerts in
      // case device gets back online and sends them all at once.
      // Keep updating the most recent alert during this period.
      if (!openDialogTimeoutRef.current) {
        openDialogTimeoutRef.current = setTimeout(async () => {
          openDialogTimeoutRef.current = undefined
          if (alert.current?.isHandled) {
            // Handled alert will not be displayed anyway
            if (window.Android?.stopNotification)
              window.Android.stopNotification()
          } else await showAcknowledgeDialog()
        }, MERGE_BATCHES_DURATION)
      }

      async function showAcknowledgeDialog() {
        const deferred = new Deferred<void>()
        setDialogDeferred(deferred)
        try {
          await deferred.promise
        } catch (error) {
          if (error !== USER_CLOSED_DIALOG) {
            throw error
          }
        } finally {
          // Acknowledge even when tapping outside to dismiss
          // Stop phone notification
          if (window.Android?.stopNotification)
            window.Android.stopNotification()

          // EDIT onAlertAcknowledged also stopNotification in v2
          if (window.Android?.onAlertAcknowledged) {
            window.Android.onAlertAcknowledged(NO_ALERT_ID, serial)
          }

          closeDialog()
        }
      }
    },
    // Keep this empty, otherwise reference would change, triggering a
    // new call to onAlertReceived from Room, resulting in an infinite loop.
    [],
  )

  useEffect(() => {
    if (closeDialogTimeoutRef.current)
      clearTimeout(closeDialogTimeoutRef.current)

    if (dialogDeferred && alert.current) {
      // Note diffNow is a negative value
      const delay =
        CLOSE_DELAY + alert.current.dateTime.diffNow().as('milliseconds')

      closeDialogTimeoutRef.current = setTimeout(closeDialog, delay)
    } else {
      closeDialogTimeoutRef.current = undefined
    }
  }, [dialogDeferred])

  return (
    <AlertContext.Provider
      value={{ onAlertReceived, notificationAlertId: alert.current?.alertId }}
    >
      {dialogDeferred && alert.current && (
        <AcknowledgeAlertDialog
          room={alert.current.room}
          roomName={alert.current.roomName}
          deferred={dialogDeferred}
        />
      )}
      {children}
    </AlertContext.Provider>
  )
}
