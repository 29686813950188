import { DateTime } from 'luxon'
import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { Deferred } from 'shared/utils/web/deferred'
import { DialogButton } from '../../components/Button'
import {
  Dialog,
  USER_BACK_BUTTON,
  USER_CLOSED_DIALOG,
} from '../../components/Dialog'
import { useFirebase } from '../../hooks/useFirebase'
import Arrow from '../../icons/arrow.svg?react'

export const PostMessageDialog: React.FC<{
  deferred: Deferred<string>
  facilityId: string
}> = ({ deferred, facilityId }) => {
  const [message, setMessage] = useState('')

  const {
    data: facilityMessages,
    loading,
    error,
  } = useFirebase(`messages/${facilityId}`)

  const listRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    listRef?.current?.scrollTo(0, listRef?.current?.scrollHeight)
  }, [facilityMessages])

  return (
    <Dialog onClose={() => deferred.reject(USER_CLOSED_DIALOG)}>
      <div
        className="flex cursor-pointer flex-row items-center space-x-2"
        onClick={() => deferred.reject(USER_BACK_BUTTON)}
      >
        <Arrow className="w-4" fill="currentColor" />
        <span className="flex-1">Messagerie</span>
      </div>

      {loading ? (
        <div className="text-center">Chargement...</div>
      ) : error ? (
        <div className="text-center">Erreur</div>
      ) : facilityMessages === undefined ? (
        <div className="text-center">Aucun message</div>
      ) : (
        <div
          className="flex max-h-[40vh] flex-col space-y-3 overflow-y-scroll text-base"
          ref={listRef}
        >
          {Object.entries(facilityMessages)
            .sort(
              (
                [_keyA, { timeStamp: timeStampA }],
                [_keyB, { timeStamp: timeStampB }],
              ) => timeStampA - timeStampB,
            )
            .map(([key, message]) => (
              <div
                className={`flex flex-row ${
                  message.fromAdmin ? 'justify-start' : 'justify-end'
                }`}
                key={key}
              >
                <div
                  className={`max-w-[90%] bg-opacity-40 p-2 dark:bg-opacity-40 ${
                    message.fromAdmin
                      ? 'bg-background dark:bg-background-dark text-left'
                      : 'bg-background-alert dark:bg-background-alert-dark text-right'
                  }`}
                >
                  <div className="whitespace-pre-wrap">{message.text}</div>
                  <div className="text-sm">
                    {DateTime.fromMillis(message.timeStamp).toLocaleString(
                      DateTime.DATETIME_SHORT,
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>
      )}
      <textarea
        rows={3}
        placeholder="Une chute non détectée par ARI&nbsp;? Une idée pour améliorer notre service&nbsp;?"
        autoFocus
        className="border-2 border-current bg-transparent px-2 py-1 text-base focus:outline-none"
        value={message}
        onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
          setMessage(e.currentTarget.value)
        }
      />
      <div className="flex flex-row space-x-4 self-center">
        <DialogButton onClick={() => deferred.reject(USER_CLOSED_DIALOG)}>
          Annuler
        </DialogButton>
        <DialogButton
          disabled={message.trim().length === 0}
          onClick={() => deferred.resolve(message.trim())}
        >
          Valider
        </DialogButton>
      </div>
    </Dialog>
  )
}
