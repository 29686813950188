import React, { useMemo, useState } from 'react'
import { serverTimestamp } from 'shared/firebase/serverValue'
import { DialogButton } from '../../components/Button'
import { Dialog } from '../../components/Dialog'
import { useASUser } from '../../components/UserProvider'
import { set } from '../../firebaseMethods'
import { useFirebase } from '../../hooks/useFirebase'
import dayNight from './day-night.png'
import events from './events.png'
import nightMode from './night-mode.png'
import roomExit from './roomExit.png'

type Message = {
  id: string
  Component: React.FC
}

const MESSAGES: Message[] = [
  {
    id: 'dark',
    Component: () => (
      <>
        <div className="mb-2 text-center">
          Pour mieux vous accompagner dans vos nuits,
          <br />
          ARI fait peau neuve et passe au mode sombre !
        </div>
        <img
          src={nightMode}
          alt="Mode nuit"
          className="mb-3 w-1/2 self-center"
        />
      </>
    ),
  },
  {
    id: 'dayView',
    Component: () => (
      <>
        <div className="text-center">
          Les alertes de la nuit peuvent désormais être ré-écoutées la journée
          suivante.
        </div>
        <img
          src={dayNight}
          alt="Alertes le jour"
          className="my-3 w-2/3 self-center"
        />
      </>
    ),
  },
  {
    id: 'events',
    Component: () => (
      <>
        <div className="text-center">
          Les chambres dans lesquelles de l'activité est en cours sont mises en
          surbrillance.
        </div>
        <img
          src={events}
          alt="Événements en cours"
          className="my-3 w-4/5 self-center"
        />
      </>
    ),
  },
]

export const Messages: React.FC<{
  facility: Facility
}> = ({ facility }) => {
  const [areMessagesHidden, setAreMessagesHidden] = useState(false)
  const { uid } = useASUser()

  const seenMessagesPath = `seenMessages/${uid}` as const

  const { data: seenMessages, loading, error } = useFirebase(seenMessagesPath)

  // PERSONALIZATION
  if (facility.name === 'BEAUREPAIRE-AFIPH') {
    MESSAGES.push({
      id: 'roomExit',
      Component: () => (
        <>
          <div className="text-center">
            Un nouveau détecteur de sortie de chambre a été activé dans l'aile
            F.
          </div>
          <img
            src={roomExit}
            alt="Sortie de chambre"
            className="my-3 w-4/5 self-center"
          />
        </>
      ),
    })
  }

  const firstUnseenMessage = useMemo(() => {
    if (loading || error) {
      return null
    }

    if (!seenMessages) {
      return MESSAGES[0]
    }

    return MESSAGES.find(({ id }) => !seenMessages[id])
  }, [seenMessages, loading, error])

  if (areMessagesHidden || !firstUnseenMessage) {
    return null
  }

  return (
    <Dialog onClose={() => setAreMessagesHidden(true)}>
      <firstUnseenMessage.Component />
      <div className="flex flex-row space-x-4 self-center">
        <DialogButton
          onClick={() => {
            set(
              `${seenMessagesPath}/${firstUnseenMessage.id}`,
              serverTimestamp(),
            )
          }}
        >
          Ok, j'ai compris
        </DialogButton>
      </div>
    </Dialog>
  )
}
