import React from 'react'
import { Alert } from './Alert'

type AlertEntry = [FirebaseKey, Alert]

export const Alerts: React.FC<{
  serial: string
  alertEntries: AlertEntry[]
  isMonitoring: boolean
  isOwnership: boolean
}> = ({ serial, alertEntries, isMonitoring, isOwnership }) => {
  if (alertEntries.length === 0) {
    return null
  }

  return (
    <div className="flex flex-col space-y-3">
      {alertEntries.map(([alertId, alert]) => (
        <Alert
          key={alertId}
          alert={alert}
          serial={serial}
          alertId={alertId}
          isOwnership={isOwnership}
          isMonitoring={isMonitoring}
        />
      ))}
    </div>
  )
}
