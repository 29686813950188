import React from 'react'
import { NavLink } from 'react-router-dom'

const StyledLink: React.FC<React.PropsWithChildren<{ to: string }>> = ({
  to,
  children,
}) => (
  <NavLink
    to={to}
    className={({ isActive }) =>
      isActive ? 'border-b-2 border-current px-4 py-1' : 'px-4 py-1'
    }
  >
    {children}
  </NavLink>
)

export const Tabs: React.FC = () => {
  return (
    <div className="z-10 flex flex-row gap-4 bg-gray-700 px-3 shadow shadow-neutral-800">
      <StyledLink to="alerts">Alertes</StyledLink>
      <StyledLink to="live">AS</StyledLink>
      <StyledLink to="admin">Admin</StyledLink>
      <StyledLink to="exports">Exports</StyledLink>
    </div>
  )
}
