import React, { useMemo, useState } from 'react'
import { Centered } from 'shared/components/Centered'
import { FacilityId } from 'shared/types/utils'
import { collator } from 'shared/utils/web/collator'
import { useFirebase } from '../hooks/useFirebase'

type FacilitiesContext = {
  facilities: Record<FirebaseKey, Facility>
  facility: Facility
  facilityId: FirebaseKey
  setFacilityId: (id: FirebaseKey) => void
}

export const facilitiesContext = React.createContext<FacilitiesContext>({
  facilities: {},
  facility: null as unknown as Facility,
  facilityId: '',
  setFacilityId: (_facilityId: FirebaseKey) => {
    // do nothing
  },
})

export const FacilitiesProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const selectedFacilityIdStorageKey = 'selectedFacilityId'

  const [facilityId, setFacilityIdHelper] = useState('')

  function setFacilityId(facilityId: FirebaseKey) {
    localStorage.setItem(selectedFacilityIdStorageKey, facilityId)
    setFacilityIdHelper(facilityId)
  }

  const { data, loading, error } = useFirebase('facilities')

  const facilities = useMemo(() => {
    if (!data) return {}

    const result = Object.entries(data)
      .sort(([, { name: nameA }], [, { name: nameB }]) =>
        collator.compare(nameA, nameB),
      )
      .reduce<Record<FacilityId, Facility>>((acc, [facilityId, facility]) => {
        return { ...acc, [facilityId]: facility }
      }, {})

    const storedFacilityId = localStorage.getItem(selectedFacilityIdStorageKey)
    if (storedFacilityId && Object.keys(result).includes(storedFacilityId))
      setFacilityIdHelper(storedFacilityId)
    else setFacilityIdHelper(Object.keys(result)[0])

    return result
  }, [data])

  const contextValue = {
    facilities,
    facilityId,
    facility: facilities[facilityId],
    setFacilityId,
  }

  return (
    <>
      {loading ? (
        <Centered>Chargement...</Centered>
      ) : error ? (
        <Centered>Erreur lors du chargement des données</Centered>
      ) : (
        <facilitiesContext.Provider value={contextValue}>
          {children}
        </facilitiesContext.Provider>
      )}
    </>
  )
}
