import { ErrorBoundary } from '@sentry/react'
import React, { useEffect } from 'react'
import { Centered } from 'shared/components/Centered'
import { ms } from 'shared/utils/time'
import { onError } from 'shared/utils/web/error'
import { YellowButton } from '../../components/Button'
import { CurrentlyPlayingProvider } from '../../components/Player'
import { Theme } from '../../components/Theme'
import { UpdateAppBanner } from '../../components/UpdateAppBanner'
import { UserProvider } from '../../components/UserProvider'
import { useAuth } from '../../hooks/useAuth'
import { useFirebase } from '../../hooks/useFirebase'
import { useIsOnline } from '../../hooks/useIsOnline'
import { Login } from '../Login/Login'
import { AdminContent, ASContent } from './Content'
import { OfflineScreen } from './OfflineScreen'
import { PasswordReset } from './PasswordReset'

export const App = () => {
  const isOnline = useIsOnline()

  return (
    <React.StrictMode>
      <ErrorBoundary
        fallback={
          <div className="bg-background dark:bg-background-dark text-primary dark:text-primary-dark flex h-screen flex-col items-center justify-center space-y-3">
            <div>Une erreur est survenue</div>
            <YellowButton
              onClick={() => {
                window.location.reload()
              }}
            >
              Rafraîchir
            </YellowButton>
          </div>
        }
      >
        <Theme />
        {isOnline ? <Authentification /> : <OfflineScreen />}
        <PasswordReset />
        {import.meta.env.MODE !== 'production' && (
          <div className="pointer-events-none fixed bottom-2 right-2 text-[#bef264]">
            <b>STAGING</b>
          </div>
        )}
      </ErrorBoundary>
    </React.StrictMode>
  )
}

const Authentification: React.FC = () => {
  const { data: user, loading, error } = useAuth()

  useEffect(() => {
    if (loading) {
      const timer = setTimeout(
        () => {
          onError('Authentification takes too long')
          window.location.reload()
        },
        ms(30, 'seconds'),
      )
      return () => clearTimeout(timer)
    }
    return
  }, [loading])

  useEffect(() => {
    if (error !== null) {
      onError(error)
      const timer = setTimeout(
        () => {
          onError('Reload page after Authentification error')
          window.location.reload()
        },
        ms(2, 'minutes'),
      )
      return () => clearTimeout(timer)
    }
    return
  }, [error])

  return (
    <div className="bg-background dark:bg-background-dark text-primary dark:text-primary-dark">
      <div className="flex min-h-screen flex-col text-xl">
        {loading ? (
          <Centered>Authentification...</Centered>
        ) : error ? (
          <Centered>Erreur</Centered>
        ) : user === null ? (
          <Login />
        ) : (
          <Authorization uid={user.uid} />
        )}
      </div>
      <UpdateAppBanner />
    </div>
  )
}

const Authorization: React.FC<{ uid: string }> = ({ uid }) => {
  const { data: user, loading, error } = useFirebase(`users/${uid}`)

  useEffect(() => {
    if (loading) {
      const timer = setTimeout(
        () => {
          onError('Authorization takes too long')
          window.location.reload()
        },
        ms(30, 'seconds'),
      )
      return () => clearTimeout(timer)
    }
    return
  }, [loading])

  useEffect(() => {
    if (error !== null) {
      onError(error)
      const timer = setTimeout(
        () => {
          onError('Reload page after Authorization error')
          window.location.reload()
        },
        ms(2, 'minutes'),
      )
      return () => clearTimeout(timer)
    }
    return
  }, [error])

  useEffect(() => {
    if (loading || error) return
    if (user === undefined) onError(`No user credentials set for uid ${uid}`)
  }, [loading, error, user, uid])

  return loading ? (
    <Centered>Autorisation...</Centered>
  ) : error ? (
    <Centered>Erreur</Centered>
  ) : !user ? (
    <Centered>Utilisateur invalide</Centered>
  ) : (
    <UserProvider user={{ uid, ...user }}>
      <CurrentlyPlayingProvider>
        {user.role === 'admin' ? <AdminContent /> : <ASContent />}
      </CurrentlyPlayingProvider>
    </UserProvider>
  )
}
