import { alertEvaluation, alertValidation } from 'common/alertQueries'
import { automaticUid } from 'common/types'
import { DateTime } from 'luxon'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { useRefreshInterval } from 'shared/hooks/useRefreshInterval'
import {
  Alert,
  AlertType,
  AlertValidation,
  alertTypeColor,
  alertTypeString,
} from 'shared/types/alert'
import { isObjectEmpty } from 'shared/utils/defined'
import { getDeviceStatusDisplay, isDeviceMonitored } from 'shared/utils/device'
import { plural } from 'shared/utils/plural'
import { formatIsoTime, formatMsTime, ms, ms2sec } from 'shared/utils/time'
import { siteUrl } from 'shared/utils/url'
import { Deferred } from 'shared/utils/web/deferred'
import { GrayButton } from '../../components/Button'
import { USER_CLOSED_DIALOG } from '../../components/Dialog'
import { Player } from '../../components/Player'
import { Select } from '../../components/Select'
import { useUser } from '../../components/UserProvider'
import { get, push, remove, set } from '../../firebaseMethods'
import { useFirebase } from '../../hooks/useFirebase'
import { Event as EventProps } from './DevicesAlerts'
import { LeaveCommentDialog } from './LeaveCommentDialog'
import { PhoneCallsStatus } from './PhoneCallStatus'
import { SnoozeAlertsDialog } from './SnoozeAlertsDialog'
import { ValidateAlertDialog } from './ValidateAlertDialog'

const formatSnoozeEndTime = (snoozeEndTime: number | undefined) => {
  if (snoozeEndTime === undefined) return '?'
  const diffNow = DateTime.fromMillis(snoozeEndTime).diffNow()
  if (diffNow.as('hours') < 1) return diffNow.toFormat("mm 'min'")
  else if (diffNow.as('hours') < 10) return diffNow.toFormat("h'h'mm'm'")
  else return diffNow.toFormat("hh'h'mm'm'")
}

const formatDuration = (start: string, end: number) => {
  const startTimeStamp = new Date(start).valueOf()
  const totalSeconds = Math.round(ms2sec(end - startTimeStamp))
  const seconds = totalSeconds % 60
  const minutes = Math.floor(totalSeconds / 60)
  return `${minutes}m ${seconds < 10 ? '0' : ''}${seconds}s`
}

function alertDot(type: AlertType, validation: AlertValidation | undefined) {
  return (
    <div
      className={`flex flex-col items-center justify-center rounded-full ${
        alertTypeColor[type]
      } ${
        validation?.isValidated ? 'h-4 w-4 border-2 border-white' : 'h-3 w-3'
      }`}
      title={type}
    />
  )
}

// Summarize AS alert handling. If at least one finds it useful : true
// else if one indicated useless : false, undefined if no AS evaluated
function handlingAsUseful(handling: Alert['handling']) {
  return Object.values(handling ?? {}).reduce<boolean | undefined>(
    (acc, alertHandling) => {
      if (acc || alertHandling.isUseful) return true
      if (alertHandling.isUseful === false) return false
      return undefined
    },
    undefined,
  )
}

const REFRESH_INTERVAL = ms(1, 'minute')

const CANCEL_IGNORE_DURATION = 10 // minutes
const VALIDATION_PERIOD = 60 // minutes, time after which alert can no longer be validated

const NB_DAYS = 15

interface Stats {
  total: number
  validated: number
}

const StatsLine: React.FC<{ stats: Stats | undefined; period: string }> = ({
  stats,
  period,
}) => {
  if (stats === undefined)
    return <div className="w-1/2 rounded bg-slate-700">&nbsp;</div>

  return (
    <div>
      {`${period} : ${plural(stats.total, 'alerte', false)}, ${plural(
        stats.validated,
        'validée',
        false,
      )}`}
    </div>
  )
}

export const Event: React.FC<EventProps> = ({
  alerts,
  date,
  serial,
  device,
  facilityName,
  snoozeHistory,
  priorityAlertIndex,
}) => {
  useRefreshInterval(REFRESH_INTERVAL)

  const [leaveCommentDialogDeferred, setLeaveCommentDialogDeferred] =
    useState<Deferred<string> | null>(null)

  const [validateAlertDialogDeferred, setValidateAlertDialogDeferred] =
    useState<Deferred<void> | null>(null)

  const { uid } = useUser()

  const [selectedAlertIndex, setSelectedAlertIndex] = useState<number>(0)

  const selectedAlert = alerts[selectedAlertIndex]
  const selectedAlertPath =
    `alerts/${date}/${serial}/${selectedAlert.key}` as const

  const [snoozeAlertsDialogDeferred, setSnoozeAlertsDialogDeferred] =
    useState<Deferred<number> | null>(null)

  const firstTime = DateTime.fromISO(alerts[alerts.length - 1].date).valueOf()
  const lastTime = DateTime.fromISO(alerts[0].date).valueOf()
  const eventStartTime = firstTime - ms(20, 'seconds')
  const eventEndTime = Math.min(lastTime + ms(40, 'seconds'), Date.now())
  const eventDuration = eventEndTime - eventStartTime

  const time = DateTime.fromISO(selectedAlert.date).toLocaleString(
    DateTime.TIME_SIMPLE,
  )

  const [stats, setStats] = useState<Stats>()
  const [currentNightStats, setCurrentNightStats] = useState<Stats>()

  useEffect(() => {
    setSelectedAlertIndex(priorityAlertIndex >= 0 ? priorityAlertIndex : 0)
  }, [priorityAlertIndex])

  useEffect(() => {
    function computeStats(alerts: Alert[]) {
      const stats: Stats = {
        total: alerts.length,
        validated: alerts.filter((alert) => alert.validation?.isValidated)
          .length,
      }

      return stats
    }

    const computeAllStats = async () => {
      const startDate = DateTime.fromISO(date)
      const dates = Array(NB_DAYS)
        .fill(0)
        .map((_, i) => startDate.minus({ days: i }).toISODate())

      const recentAlertsByDate = await Promise.all(
        dates.map((date) =>
          get(`alerts/${date}/${serial}`).then((alerts) =>
            Object.values(alerts ?? {}),
          ),
        ),
      )

      const recentAlerts = recentAlertsByDate.flat()
      setStats(computeStats(recentAlerts))

      const currentNightAlerts = recentAlertsByDate[0]
      setCurrentNightStats(computeStats(currentNightAlerts))
    }

    computeAllStats()
  }, [date, serial])

  const { data: roomComment } = useFirebase(`comments/${serial}`)

  function clampTime(time: number) {
    return Math.min(eventEndTime, Math.max(eventStartTime, time))
  }

  function percent(duration: number) {
    return `${(100.0 * duration) / eventDuration}%`
  }

  function leftPercent(time: number) {
    return percent(clampTime(time) - eventStartTime)
  }

  function widthPercent(startTime: number, endTime: number) {
    return percent(clampTime(endTime) - clampTime(startTime))
  }

  const handleSnoozeClick = async (
    type: AlertType,
    snoozeKey: string | undefined,
  ) => {
    const deferred = new Deferred<number>()
    setSnoozeAlertsDialogDeferred(deferred)
    try {
      const snoozeEndTime = await deferred.promise
      const snoozePath = `snooze/${date}/${serial}/${type}` as const

      if (snoozeKey === undefined) {
        push(snoozePath, { start: Date.now(), end: snoozeEndTime })
      } else {
        set(`${snoozePath}/${snoozeKey}/end`, snoozeEndTime)
      }
    } catch (error) {
      if (error !== USER_CLOSED_DIALOG) {
        throw error
      }
    } finally {
      setSnoozeAlertsDialogDeferred(null)
    }
  }

  const handleValidateAlert = async () => {
    const deferred = new Deferred<void>()
    setValidateAlertDialogDeferred(deferred)
    try {
      await deferred.promise
      const validation = alertValidation(uid)
      await set(`${selectedAlertPath}/validation`, validation)
    } catch (error) {
      if (error !== USER_CLOSED_DIALOG) {
        throw error
      }
    } finally {
      setValidateAlertDialogDeferred(null)
    }
  }

  const handleUnIgnore = async () => {
    await remove(`${selectedAlertPath}/validation`)
  }

  const handleComment = async () => {
    const deferred = new Deferred<string>()
    setLeaveCommentDialogDeferred(deferred)
    try {
      const comment = await deferred.promise
      await set(`${selectedAlertPath}/comment`, comment)
    } catch (error) {
      if (error !== USER_CLOSED_DIALOG) {
        throw error
      }
    } finally {
      setLeaveCommentDialogDeferred(null)
    }
  }

  const handleAlertEvaluationChange = async (
    event: ChangeEvent<HTMLSelectElement>,
  ) => {
    const isConfirmed = JSON.parse(event.target.value)

    if (isConfirmed !== null) {
      const evaluation = alertEvaluation(uid, isConfirmed)
      await set(`${selectedAlertPath}/evaluation`, evaluation)
    } else await remove(`${selectedAlertPath}/evaluation`)
  }

  const currentlyActiveSnoozeIntervals = Object.entries(
    snoozeHistory ?? {},
  ).reduce<Partial<Record<AlertType, [FirebaseKey, number]>>>(
    (acc, [type, snoozeTypeHistory]) => {
      const mostRecentSnoozeKey = Object.keys(snoozeTypeHistory)
        .sort()
        .pop() as FirebaseKey
      const snoozeInterval = snoozeTypeHistory[mostRecentSnoozeKey]
      if (snoozeInterval.end >= Date.now())
        acc[type as AlertType] = [mostRecentSnoozeKey, snoozeInterval.end]
      return acc
    },
    {},
  )

  return (
    <>
      <div className="flex flex-col">
        <div className="flex flex-col gap-3 rounded-xl bg-gray-800 p-3 drop-shadow-lg">
          <div className="flex flex-row justify-between">
            <b>
              {facilityName} - Chambre {device.room} - {time}
            </b>
            {!isDeviceMonitored(device) && (
              <b className="rounded-full bg-indigo-900 px-3">
                {getDeviceStatusDisplay(device.status)}
              </b>
            )}
          </div>

          {roomComment && <div>{roomComment}</div>}

          <StatsLine stats={currentNightStats} period="Journée en cours" />

          <StatsLine stats={stats} period={`${NB_DAYS} jours`} />

          {isDeviceMonitored(device) && (
            <div className="flex flex-row gap-4">
              {!isObjectEmpty(currentlyActiveSnoozeIntervals) && 'Sourdine'}
              {Object.entries(currentlyActiveSnoozeIntervals).map(
                ([type, [snoozeIntervalKey, snoozeIntervalEnd]]) => (
                  <div
                    key={type}
                    className="flex cursor-pointer flex-row items-center gap-1 rounded bg-gray-500 px-2"
                    onClick={() =>
                      handleSnoozeClick(type as AlertType, snoozeIntervalKey)
                    }
                  >
                    {alertDot(type as AlertType, undefined)}
                    {formatSnoozeEndTime(snoozeIntervalEnd)}
                  </div>
                ),
              )}
              {DateTime.fromISO(alerts[0].date).diffNow().as('minutes') > -30 &&
                !Object.keys(currentlyActiveSnoozeIntervals).includes(
                  selectedAlert.type,
                ) && (
                  <div
                    className="flex cursor-pointer flex-row items-center gap-1 rounded bg-gray-500 px-2"
                    onClick={() =>
                      handleSnoozeClick(selectedAlert.type, undefined)
                    }
                  >
                    {alertDot(selectedAlert.type, undefined)}
                    Mettre en sourdine
                  </div>
                )}
            </div>
          )}

          <div className="flex flex-row items-center gap-4">
            {formatMsTime(eventStartTime)}
            <div className="relative h-4 flex-1 rounded bg-slate-700">
              {Object.entries(snoozeHistory ?? {})
                .map(([type, snoozeTypeHistory]) =>
                  Object.values(snoozeTypeHistory).map(
                    (snoozeInterval, index) => {
                      if (
                        snoozeInterval.start > eventEndTime ||
                        snoozeInterval.end < eventStartTime
                      )
                        return null

                      return (
                        <div
                          key={`${type}-${index}`}
                          className={`absolute inset-y-1 bg-opacity-50 ${
                            alertTypeColor[type as AlertType]
                          }`}
                          style={{
                            left: leftPercent(snoozeInterval.start),
                            width: widthPercent(
                              snoozeInterval.start,
                              snoozeInterval.end,
                            ),
                          }}
                          title={`Sourdine ${type} de ${formatMsTime(
                            snoozeInterval.start,
                          )} à ${formatMsTime(snoozeInterval.end)}`}
                        />
                      )
                    },
                  ),
                )
                .flat()}
              {alerts.map((alert, index) => (
                <div
                  key={alert.key}
                  className="absolute inset-y-0 flex -translate-x-1/2 cursor-pointer flex-col items-center justify-center"
                  title={`${formatIsoTime(alert.date)} ${alert.type}`}
                  style={{
                    left: leftPercent(DateTime.fromISO(alert.date).valueOf()),
                  }}
                  onClick={() => setSelectedAlertIndex(index)}
                >
                  {alertDot(alert.type, alert.validation)}
                  {alert.validation?.uid !== automaticUid &&
                    alert.validation?.isValidated && (
                      <div className="absolute -top-4 text-xs">Manu</div>
                    )}
                  {index === priorityAlertIndex && (
                    <div className="absolute -top-5 flex h-6 w-6 flex-col items-center justify-center rounded-full bg-pink-400 font-bold">
                      !
                    </div>
                  )}
                  {index === selectedAlertIndex && (
                    <div className="absolute -bottom-5">▲</div>
                  )}
                </div>
              ))}
            </div>
            {formatMsTime(eventEndTime)}
          </div>
        </div>

        <div
          className={`relative -top-1 mx-auto flex w-11/12 flex-col gap-3 rounded-xl bg-gray-800 p-3 drop-shadow-lg ${
            selectedAlert.validation?.isValidated
              ? 'border-2 border-white'
              : 'border-t border-t-gray-400'
          }`}
        >
          <div className="flex flex-row items-center gap-8">
            <div className="flex flex-1 flex-row items-center gap-2">
              <span>{formatIsoTime(selectedAlert.date)}&nbsp;</span>
              {alertDot(selectedAlert.type, selectedAlert.validation)}
              <span>{alertTypeString[selectedAlert.type]}</span>
              {selectedAlert.type === 'SPEECH' ||
              selectedAlert.type === 'AS_CALL' ? (
                <i>{selectedAlert.text || 'Pas de retranscription'}</i>
              ) : (
                <span>{selectedAlert.text}</span>
              )}
            </div>
            <Player soundURI={selectedAlert.soundURL} small={true} />
            <div>
              <a
                href={`${siteUrl('listen')}/${serial}/${date}/${
                  selectedAlert.date
                }`}
                target="_blank"
                rel="noopener noreferrer"
                className="rounded-full bg-current px-2 py-1 no-underline"
              >
                <span className="text-black">&rarr;</span>
              </a>
            </div>
          </div>

          <PhoneCallsStatus phoneCalls={selectedAlert.phoneCalls} />

          {selectedAlert.validation === undefined &&
            isDeviceMonitored(device) &&
            DateTime.fromISO(selectedAlert.date).diffNow().as('minutes') >
              -VALIDATION_PERIOD && (
              <div className="flex flex-row justify-evenly">
                <GrayButton onClick={handleValidateAlert}>Valider</GrayButton>
              </div>
            )}

          {selectedAlert.validation?.isValidated === false &&
            DateTime.fromISO(selectedAlert.date).diffNow().as('minutes') >
              -CANCEL_IGNORE_DURATION && (
              <div className="flex flex-row justify-evenly">
                <GrayButton onClick={handleUnIgnore}>Dé-ignorer</GrayButton>
              </div>
            )}

          {selectedAlert.validation !== undefined && (
            <div className="flex flex-row flex-wrap items-baseline justify-between gap-2">
              <span>
                {`${
                  selectedAlert.validation.isValidated
                    ? 'Alerte validée'
                    : 'Alerte ignorée'
                }`}
                {selectedAlert.validation.isValidated &&
                  ` ${formatDuration(
                    selectedAlert.date,
                    selectedAlert.validation.timeStamp,
                  )} après`}
              </span>
              <div className="flex flex-row flex-wrap justify-end gap-6">
                {Object.values(selectedAlert.handling ?? {}).map(
                  (alertHandling) => (
                    <span key={alertHandling.timeStamp}>
                      <b>
                        {formatDuration(
                          selectedAlert.date,
                          alertHandling.timeStamp,
                        )}
                      </b>
                      &nbsp;({Math.round(100 * alertHandling.playProgress)}%)
                    </span>
                  ),
                )}
              </div>
            </div>
          )}

          {(selectedAlert.validation !== undefined ||
            !isDeviceMonitored(device)) && (
            <>
              <div className="flex flex-row justify-between">
                <div className="flex flex-row items-baseline gap-2">
                  <span>Évaluation</span>
                  {alerts.every((alert) => alert.evaluation === undefined) &&
                    isDeviceMonitored(device) && (
                      <span className="flex h-6 w-6 flex-col items-center justify-center rounded-full bg-pink-400 font-bold">
                        !
                      </span>
                    )}
                  <Select
                    className="bg-gray-500"
                    value={
                      selectedAlert.evaluation
                        ? JSON.stringify(selectedAlert.evaluation.isConfirmed)
                        : 'null'
                    }
                    onChange={handleAlertEvaluationChange}
                  >
                    <option value="null">Non renseigné</option>
                    <option value='"UNKNOWN"'>Ne sait pas</option>
                    <option value="true">Alerte réelle</option>
                    <option value="false">Fausse alerte</option>
                  </Select>
                </div>
                <span>
                  {handlingAsUseful(selectedAlert.handling) === true
                    ? 'Évaluation AS : 👍'
                    : handlingAsUseful(selectedAlert.handling) === false
                      ? 'Évaluation AS : 👎'
                      : ''}
                </span>
              </div>
              <div className="flex flex-row items-center gap-2">
                <GrayButton onClick={handleComment}>
                  <span
                    className={
                      selectedAlert.evaluation?.isConfirmed === false &&
                      !selectedAlert.comment
                        ? 'text-red-400'
                        : ''
                    }
                  >
                    {selectedAlert.comment ? '✏️' : 'Ajouter un commentaire'}
                  </span>
                </GrayButton>
                {selectedAlert.evaluation?.isConfirmed === false &&
                  !selectedAlert.comment && (
                    <span className="flex h-6 w-6 flex-col items-center justify-center rounded-full bg-pink-400 font-bold">
                      !
                    </span>
                  )}
                {selectedAlert.comment}
              </div>
            </>
          )}
        </div>
      </div>

      {snoozeAlertsDialogDeferred && (
        <SnoozeAlertsDialog deferred={snoozeAlertsDialogDeferred} />
      )}

      {leaveCommentDialogDeferred && (
        <LeaveCommentDialog
          initialComment={selectedAlert.comment}
          deferred={leaveCommentDialogDeferred}
        />
      )}

      {validateAlertDialogDeferred && (
        <ValidateAlertDialog deferred={validateAlertDialogDeferred} />
      )}
    </>
  )
}
