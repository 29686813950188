import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import PauseButton from '../icons/pause.svg?react'
import PlayButton from '../icons/play.svg?react'

export const CurrentlyPlayingContext = React.createContext<
  (audio: HTMLAudioElement | null) => void
>(() => {
  // do nothing
})

export const CurrentlyPlayingProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [currentlyPlaying, setCurrentlyPlaying] =
    useState<HTMLAudioElement | null>(null)

  const contextValue = useCallback(
    (newCurrentlyPlaying: HTMLAudioElement | null) => {
      if (currentlyPlaying && newCurrentlyPlaying) {
        currentlyPlaying.pause()
      }
      setCurrentlyPlaying(newCurrentlyPlaying)
    },
    [currentlyPlaying],
  )

  return (
    <CurrentlyPlayingContext.Provider value={contextValue}>
      {children}
    </CurrentlyPlayingContext.Provider>
  )
}

export const Player: React.FC<{
  soundURI: string
  small?: boolean
  onPlayPauseEvent?: (isPlaying: boolean, progress: number) => void
  onPlayProgress?: (progress: number) => void
}> = ({ soundURI, small, onPlayPauseEvent, onPlayProgress }) => {
  const [isPlaying, setIsPlaying] = useState(false)
  const audioRef = useRef<HTMLAudioElement>(null)

  const setCurrentlyPlaying = useContext(CurrentlyPlayingContext)

  useEffect(() => {
    audioRef?.current?.pause()
    setIsPlaying(false)
    setCurrentlyPlaying(null)
  }, [soundURI]) // FIXME DEBT : setCurrentlyPlaying is missing here
  // But adding it prevents the play button from working.

  useEffect(() => {
    if (audioRef && audioRef.current) {
      const audio = audioRef.current
      audio.onended = () => {
        setIsPlaying(false)
        setCurrentlyPlaying(null)
      }
      // Warning, these are currently (03/20) not supported in Safari
      // As a result, the icon is not updated
      audio.onplay = () => {
        setIsPlaying(true)
        if (onPlayPauseEvent) {
          onPlayPauseEvent(true, audio.currentTime / audio.duration)
        }
      }
      audio.onpause = () => {
        setIsPlaying(false)
        if (onPlayPauseEvent) {
          onPlayPauseEvent(false, audio.currentTime / audio.duration)
        }
      }
      audio.ontimeupdate = () => {
        if (onPlayProgress) {
          onPlayProgress(audio.currentTime / audio.duration)
        }
      }
    }
  }, [audioRef, onPlayPauseEvent, onPlayProgress, setCurrentlyPlaying])

  const togglePlaying = () => {
    if (audioRef && audioRef.current)
      if (audioRef.current.paused) {
        audioRef.current.play()
        setCurrentlyPlaying(audioRef.current)
      } else {
        audioRef.current.pause()
        setCurrentlyPlaying(null)
      }
  }

  // Warning - style should be kept in sync with Auditer
  return (
    <>
      <div
        className={`flex flex-col ${
          small ? 'h-8 w-8' : 'h-16 w-16'
        } cursor-pointer items-center justify-center rounded-full border-2 border-current`}
        onClick={togglePlaying}
      >
        {isPlaying ? (
          <PauseButton className={`${small ? 'w-4' : 'w-8'} fill-current`} />
        ) : (
          <PlayButton className={`${small ? 'w-4' : 'w-8'} fill-current`} />
        )}
      </div>
      <audio ref={audioRef} src={soundURI} preload="auto" />
    </>
  )
}
