import { Bubble as BubbleType, isAudioBubble, isTextBubble } from 'common/types'
import { DateTime } from 'luxon'
import React from 'react'
import { useUser } from '../../components/UserProvider'

interface Props {
  bubble: BubbleType
}

export const Bubble: React.FC<Props> = ({ bubble }) => {
  const { uid } = useUser()

  if (isTextBubble(bubble)) {
    return (
      <div
        className={`max-w-[90%] rounded-lg bg-opacity-50 p-2 dark:bg-opacity-50 ${
          bubble.userId === uid
            ? 'bg-background-phone dark:bg-background-phone-dark text-right'
            : 'bg-background-alert dark:bg-background-alert-dark text-left'
        }`}
      >
        <div className="whitespace-pre-wrap">{bubble.text}</div>
        <div className="text-xs">{formatDate(bubble.timestamp)}</div>
      </div>
    )
  } else if (isAudioBubble(bubble)) {
    return (
      <audio
        src={bubble.soundURL}
        controls
        controlsList="nodownload"
        className="max-w-[80%]"
      />
    )
  }

  return null
}

function formatDate(timestamp: number) {
  return DateTime.fromMillis(timestamp).toLocaleString(
    { weekday: 'long', hour: '2-digit', minute: '2-digit', hourCycle: 'h23' },
    { locale: 'fr-fr' },
  )
}
