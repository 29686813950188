import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import { onError } from 'shared/utils/web/error'
import { storage } from '../../firebase'

export async function uploadSound(blob: Blob) {
  const metadata = { contentType: 'audio/wav' }
  const name = Date.now().toString() // FIXME
  // This `audio` path must match the storage.rules settings
  const filePath = `audio/${name}.wav`
  const storageRef = ref(storage, filePath)
  const uploadTask = await uploadBytes(storageRef, blob, metadata).catch(
    onError,
  )
  const url = uploadTask ? await getDownloadURL(uploadTask.ref) : undefined

  return [filePath, url] as const
}
